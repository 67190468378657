import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import image1 from "./../images/IMG_1397.jpg"
import image2 from "./../images/20171122_151855.jpg"
import Girls from "./../images/IMG_20170909_101257.jpg"
import Education from "./../images/education.jpg"
import Drafting from "./../images/drafting.jpg"
import Games from "./../images/games.jpg"
import Connection from "./../images/connection.jpg"
import Community from "./../images/community.jpg"
import Provision from "./../images/provision.jpg"
import Special from "./../images/special.jpg"
import Midwives from "./../images/what-we-do/midwives.jpg"
import Communication from "./../images/what-we-do/communication.jpg"
import Tailoring from "./../images/what-we-do/tailoring.jpg"
import Ultasound from "./../images/what-we-do/ultasound.jpg"

import OrientationGahini from "./../images/2024/orientation-gahini/2.jpg"
import MusanzeTraining from "./../images/2024/musanze-team/3.jpg"

const WhatWeDo = () => (
  <Layout>
    <SEO title="What we do" />
    <section
      className="section-header text-white overlay-soft-primary"
      style={{
        backgroundImage: "url(" + Education + ")",
        backgroundColor: "#cccccc",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
      }}
    >
      <div className="container">
        <div className="row min-vh-50 justify-content-center text-center align-items-center">
          <div className="col-xl-8 col-lg-9 col-md-10">
            <h1 className="display-1">What we do</h1>
          </div>
        </div>
      </div>
    </section>
    <section className="section section-lg pt-6 bg-light">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-12 text-center">
            <h4 className="h2 font-weight-bolder">Our Projects & Services</h4>
          </div>
        </div>

        <div className="row align-items-center pb-4">
          <div className="col-12 order-md-2">
            <ol className="list-decimal">
              <li>Strengthening public institutions' capacity to deliver high-quality health services through training,
                facility upgrades, quality assurance, and educational materials.
              </li>
              <li>Improving access to essential health and wellness services in underserved communities through mobile
                clinics, outreach programs, health education, and partnerships.
              </li>
              <li>Conducting research, monitoring, and evaluation to inform health policies through surveys, focus
                groups, impact assessments, and policy workshops.
              </li>
              <li>Enhancing health service accessibility through digital platforms, including telemedicine, mobile apps,
                health portals, and SMS campaigns.
              </li>
              <li>Strengthening the leadership capabilities of youth and women in health programs through training,
                mentorship, health initiatives, and community events.
              </li>
              <li>Enhancing the capacity of community leaders, youth/women peer educators, teachers and community health
                workers through training, resources, mentorship, and recognition.
              </li>
              <li>Promoting mental health support, resilience-building, and healthy lifestyles through awareness
                campaigns, wellness programs, counseling services, and peer support groups.
              </li>
              <li>Ensuring youth access to education, health, and wellness services free from discrimination and
                violence through advocacy, safe spaces, prevention programs, and health workshops.
              </li>
              <li>Strengthening programs to enhance women’s health during pregnancy and childbirth through education,
                nurse-midwife capacity building, essential supplies, and safe childbirth practices education/campaigns.
              </li>
              <li>Ensuring access to STI/HIV/AIDS testing, treatment, and education through awareness campaigns, youth
                programs, and support groups.
              </li>
              <li>Providing youth and women with comprehensive SRH education, access to contraceptive services, and
                safe, legal abortion care, while promoting awareness of sexual and reproductive rights through community
                outreach, counseling, and support services.
              </li>
              <li>Implementing community-level interventions to combat malnutrition and promote food security through
                education and nutrition support.
              </li>
              <li>Supporting prevention, care, and reintegration for adolescents, youth and women victims of gender and
                sexual violence through awareness programs, counseling services, empowerment initiatives, and
                community-based reintegration support.
              </li>
              <li>Supporting the development of evidence-based clinical guidelines for health services delivery through
                research, expert committees, training, and dissemination.
              </li>
              <li>Ensuring the availability of essential health supplies and equipment through capacity building and
                donations.
              </li>
              <li>Implementing youth-focused mental health and substance abuse prevention programs through peer
                education, counseling services, workshops on coping skills, and awareness campaigns to reduce risky
                behaviors like alcohol and drug use.
              </li>
              <li>Providing economic empowerment and social support for vulnerable adolescents, youth and women through
                skills training, financial literacy programs, microloans, and community-based support networks to
                enhance their well-being.
              </li>
            </ol>
          </div>
          <div className="col-12 col-md-2 mr-lg-auto">
            <img src="" className="w-100" alt=""/>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <img src={Provision} className="img-fluid rounded"/>
          </div>
          <div className="col-md-6">
            <img src={image2} className="img-fluid rounded"/>
          </div>
        </div>
        <div className="row py-4">
          <div className="col-md-6">
            <img src={Girls} className="img-fluid rounded"/>
          </div>
          <div className="col-md-6">
            <img src={Drafting} className="img-fluid rounded"/>
          </div>
        </div>
        <div className="row py-4">
          <div className="col-md-6">
            <img src={Communication} className="img-fluid rounded"/>
          </div>
          <div className="col-md-6">
            <img src={Tailoring} className="img-fluid rounded"/>
          </div>
        </div>

        <div className="row py-4">
          <div className="col-md-6">
            <img src={Midwives} className="img-fluid rounded"/>
          </div>
          <div className="col-md-6">
            <img src={Ultasound} className="img-fluid rounded"/>
          </div>
        </div>

        <div className="row py-4">
          <div className="col-md-6">
            <img src={Games} className="img-fluid rounded"/>
          </div>
          <div className="col-md-6">
            <img src={Connection} className="img-fluid rounded"/>
          </div>
        </div>
        <div className="row py-4">
          <div className="col-md-6">
            <img src={Community} className="img-fluid rounded"/>
          </div>
          <div className="col-md-6">
            <img src={Special} className="img-fluid rounded"/>
          </div>
        </div>
        <div className="row py-4">
          <div className="col-md-6">
            <img src={OrientationGahini} className="img-fluid rounded"/>
          </div>
          <div className="col-md-6">
            <img src={MusanzeTraining} className="img-fluid rounded"/>
          </div>
        </div>

        <div className="row  align-items-center py-4 mb-5 mb-md-2">
          <div className="col-12 col-md-10">
            <h2 className="font-weight-bolder mb-4">EXPECTED OUTCOMES</h2>
            <ol className="list-decimal pl-5">
              <li>Improved access to quality health and wellness services</li>
              <li>Increased knowledge and awareness</li>
              <li>Strengthened institutional capacity</li>
              <li>Youth and women empowerment</li>
              <li>Improved health outcomes</li>
              <li>Improved mental health support and resilience</li>
              <li>Enhanced community health engagement</li>
              <li>Reduction in gender and sexual-based violence</li>
              <li>Effective use of technology in health delivery</li>
              <li>Policy and systemic change</li>
              <li>Reduced number of teenage pregnancies</li>
              <li>Reduced rate of sexually transmitted diseases</li>
              <li>Reduced disabilities and deaths related to pregnancy</li>
              <li>Evidence generated to inform policy decisions in health and wellness services</li>
              <li>Enhanced nutrition and food security</li>
              <li>Better tracking and evaluation to ensure effectiveness, accountability, and continuous improvement
              </li>
              <li>Responsible individuals are better equipped with knowledge, skills, and resources to offer health and
                wellness services and information
              </li>
              <li>Youth and women are empowered to make informed choices about their health</li>
              <li>Expanded geographic coverage of health and wellness services</li>
              <li>Reduced misinformation, myths, stigma, and other barriers to health and wellness service uptake</li>
              <li>Reduced number of girls and women resorting to unsafe abortion and adopting risky behavior</li>
              <li>Significant improvement in socioeconomic conditions among individuals</li>
              <li>Increased self-confidence and hope for the future amongst beneficiaries</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default WhatWeDo
